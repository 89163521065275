import styled from 'styled-components'

export const ImgWrap = styled.div`
    /* height: 100%; */
`

export const Img = styled.img`
    width: 100%;
    /* height: 100%; */
    margin: 0 0 10px 0;
    padding-right: 0;
`