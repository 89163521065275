import styled from 'styled-components'

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const LogoImageContainer = styled.div`
    /* height: 40px; */
    /* width: 40px; */
    /* height: 100%; */
    /* width: 100%; */
    flex: 1;
`

export const BrandNameContainer = styled.div`
    /* height: 40px;
    width: 160px; */
    /* height: 100%; */
    /* width: 100%; */
    flex: 2;
`